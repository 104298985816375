import React from "react";
import Helmet from "react-helmet";

import Layout from '../components/layout';

import pic01 from '../assets/images/pic01.jpg'
import pic02 from '../assets/images/pic02.jpg'
import pic03 from '../assets/images/pic03.jpg'
import pic04 from '../assets/images/pic04.jpg'
import rsdimage from '../assets/images/resort_save_dest_panel.jpg'
import rslogo from '../assets/images/resort_save_logo.jpg'

import ccenter from '../assets/images/call-center.jpg'
import ffill from '../assets/images/fulfillment.jpg'
import lgen from '../assets/images/lead_gen.jpg'


class Homepage extends React.Component {
    render() {
        const siteTitle = "Quality Marketing Group";

        return (
            <Layout>
                <Helmet title={siteTitle} />

                <section id="one" className="main style1">
                    <div className="grid-wrapper">
                        <div className="col-6">
                            <header className="major">
                                <span><img src={rslogo} alt="" /></span>
                                <h2>Resort Save provides quality vacations, guaranteed. </h2>
                            </header>
                            <p>We are confident you will have a fantastic time on your vacation with us, and we stand behind that with our satisfaction guarantee. If you are not 100% satisfied with your vacation we will give you another one at equal or greater value</p>
                        </div>
                        <div className="col-6">
                            <span className="image fit"><img src={rsdimage} alt="" /></span>
                        </div>
                    </div>
                </section>

                <section id="two" className="main style2">
                    <div className="grid-wrapper">
                        <div className="col-6">
                            <ul className="major-icons">
                                <li><span className="icon style1 major fa-plane"></span></li>
                                <li><span className="icon style2 major fa-phone-square"></span></li>
                                <li><span className="icon style3 major fa-envelope-open"></span></li>
                                <li><span className="icon style4 major fa-user-circle"></span></li>
                            </ul>
                        </div>
                        <div className="col-6">
                            <header className="major">
                                <h2>What We Do</h2>
                            </header>
                            <p>We provide a portfolio of Branding, Lead Generation, Sales and Marketing, Call Center and Fulfillment services for our customers across a number of industries using cutting edge technology. </p>
                            <p>Our platforms and services create a seamless, end-to-end experience for your brand enabling marketing, order management, fulfillment and customer relationship solutions quickly. </p>
                            <p>We currently specialize in Hospitality, Vacation, Healthcare and Home Security industries and we are reaching out to even more everyday. Contact us now to see how our business is your business.</p>
                        </div>
                    </div>
                </section>

                <section id="three" className="main style1 special">
                    <div className="grid-wrapper">
                        <div className="col-12">
                            <header className="major">
                                <h2>The Quality Marketing Platform</h2>
                            </header>
                            <p>See how YOUR business is OUR business.</p>
                        </div>

                        <div className="col-4">
                            <span className="image fit"><img src={ccenter} alt="" /></span>
                            <h3>Sales and Marketing</h3>
                            <p>We provide call center services to small to large companies as an extension of your brand, driving sales, customers and brand awareness</p>
                        </div>
                        <div className="col-4">
                            <span className="image fit"><img height="223px"  src={ffill} alt="" /></span>
                            <h3>Fulfillment Services</h3>
                            <p>Our Customer Relationship Management systems provide order management, customer service and fulfillment services for your products.</p>
                        </div>
                        <div className="col-4">
                            <span className="image fit"><img height="223px" src={lgen} alt="" /></span>
                            <h3>Lead Generation</h3>
                            <p>We drive leads and manage your sales pipeline. Its all about the leads and our business is designed turn leads into customers and customers into leads. </p>
                        </div>

                    </div>
                </section>

                <section id="four" className="main style2 special">
                    <div className="container">
                        <header className="major">
                            <h2>How can we help you?</h2>
                        </header>
                        <p>Contact us for a free consultation and demonstration.</p>
                        <ul className="actions uniform">
                            <li><a href="#" className="button special">877-744-9064</a></li>
                        </ul>
                    </div>
                </section>
            </Layout>
        );
    }
}

export default Homepage;